import { isEmpty } from "lodash";
import { GET_ACTIVE_PACKAGE, GET_CLIENT_PACKAGE_INVITATIONS, GET_PACKAGE_DETAILS } from 'store/actions/package';

const initialState = {
    isAuthenticated: false,
    details: null,
    activePackage: null,
    packageInvitations: [],
    loading: true,
};

//get package details
const getPackageDetails = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    details: data.body
  };
  return stateObj;
};

// get active package details
const getActivePackageDetails = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    activePackage: !isEmpty(data.body) ? data.body : null,
  };
  return stateObj;
};

// get client packages
const getClientPackages = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data.body);
  }
  const stateObj = {
    ...state,
    packageInvitations: data.body
  };
  return stateObj;
};

const Package = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_PACKAGE_DETAILS:
          return getPackageDetails(state, payload);
        case GET_ACTIVE_PACKAGE:
          return getActivePackageDetails(state, payload);
        case GET_CLIENT_PACKAGE_INVITATIONS:
          return getClientPackages(state, payload);
        default:
        return state;
    };
}
export default Package;