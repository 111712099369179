import parse from 'html-react-parser';

const BannerMessage = ({ containerClass = "nta-banner", data }) => {
  return (
    <div
      className={containerClass}
      style={{
        backgroundColor: data?.background_color ? data?.background_color : "#fff"
      }}
    >
      {parse(data?.message)}
    </div>
  )
}

export default BannerMessage;