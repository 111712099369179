import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const useStripe = (stripeAccount) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (stripeAccount) {
      const load = async () => {
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
          stripeAccount,
        });
        setStripe(stripeInstance);
      };
      load();
    }
  }, [stripeAccount]);

  return stripe;
};

export default useStripe;