import { isObject } from "lodash";

const moment = require('moment');
const momentTZ = require('moment-timezone');
const defaultTimezone = moment.tz.guess();

export function DATE_FORMAT(inputDate){
    const formattedDate = moment(inputDate).format('MMM D, YYYY');
    return formattedDate
}

export function FORMAT_TEXT(text){
    const text_ = text.length > 20 ?  text?.substring(0, 20)+'...' : text
    return text_
}

/**
 * Formats a number as a USD price.
 * 
 * @param {number | string | null | undefined} value - The price to format.
 * @returns {string} The formatted price in USD.
 */
export const formatPrice = (value) => {
  if (!value || isNaN(value)) {
    return "0.00";
  }

  const parsedValue = parseFloat(value);
  return parsedValue.toFixed(2);
};


export function IS_BASE64(input){
    try {
        const base64Data = input.split(',')[1];
        return btoa(atob(base64Data)) === base64Data;
      } catch (e) {
        return false;
      }
}

export function UC_FIRST(string) {
  if(!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function FULL_NAME(firstName, lastName) {
  if(!firstName && !lastName) {
    return "";
  }
  let output = `${firstName}`;
  if(lastName) {
    output += ` ${lastName && " " + lastName}`;
  }
  return UC_FIRST(output);
}

export function convertTo12HourFormat(time24) {
  const time12 = moment(time24, 'HH:mm').format('hh:mm A');
  return time12;
}
export function utcTimestamp_(utcTimestamp) {
  const momentUtc = moment.utc(utcTimestamp);
  const formattedTime = momentUtc.format('hh:mm A');
  return formattedTime;
}
export function bookingdate(dateString) {
  // const parsedDate = moment(dateString);
  // const formattedDate = parsedDate.format('YYYY-MM-DD HH:mm');
  // return formattedDate;
  const selectedDate = moment(dateString, 'YYYY-MM-DD');

// Get the current time
const currentTime = moment().format('hh:mm');

// Combine the selected date and current time
const dateTime = selectedDate.format('YYYY-MM-DD') + ' ' + currentTime;
return dateTime;
}
export function bookingdatetime(dateString) {
  const parsedDate = moment(dateString);
  const formattedDate = parsedDate.format('YYYY-MM-DD');
  return formattedDate;
}

export function dateFormatData_(inputDate) {
  const time12 = moment(inputDate, 'YYYY-MM-DD HH:mm').format('dddd MMMM D, YYYY [at] h:mm a');
  return time12;
}

export function currenDay_() {
  const currentDayLowercase = moment().format('dddd').toLowerCase();
  return currentDayLowercase;
}
export function currenDatee_() {
  var currentDate = moment();
  var formattedDate = currentDate.format("YYYY-MM-DD");
  return formattedDate;
}

export function currentDayFromMonth(date, timezone = defaultTimezone){

  // moment.tz.setDefault(timezone);
  
  const formattedDate = moment(date).tz(timezone).format('dddd MMMM DD, YYYY');
  
  return formattedDate
}

export function timeZoneFormat_(selectedDate) {
  return momentTZ(selectedDate).tz('Asia/Kolkata').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
}

export function currentDayFromMonth_(selectedDate){
  let selectedDate_ = moment(selectedDate).format('dddd MMMM DD, YYYY')
  // {selectedDate ? moment(selectedDate).format('dddd MMMM DD, YYYY') : 'None'}


  // Specify the day you want
  // const dayOfWeek = momentTZ().format('dddd');
  
  
  // // Get the current date
  // const currentDate = momentTZ();
  
  // const dateForDayOfWeek = currentDate.isoWeekday(dayOfWeek);
  
  // // Format the date with timezone abbreviation
  // const formattedDate = dateForDayOfWeek.format('dddd MMMM DD, YYYY');
  return selectedDate_
  
  }

  export function convertTimezone(dateString, timezone, format = "YYYY-MM-DD HH:mm") {
    const convertedDate = momentTZ(dateString, format).tz(timezone).toDate();
    return convertedDate;
  }

  export function dateTimeRange(startDateTime, endDateTime) {
    let startDate = moment(startDateTime, "DD-MM-YYYY");
    let endDate = moment(endDateTime, "DD-MM-YYYY");
    let output = `${moment(startDateTime).format("ddd MMM DD hh:mma")} to `;
    if(startDate.isSame(endDate)){
      output += `${moment(endDateTime).format("hh:mma")} `;
    } else {
      output = `${moment(endDateTime).format("ddd MMM DD hh:mma")} `;
    }
    output += `${moment(endDateTime).tz(moment.tz.guess()).format("z")}`;
    return output;
  }
  
  export function formatText(text) {
    text = text.replaceAll("_", "-");
    return UC_FIRST(text);
  }

  export function isDateValid (date, settings = {}, debug = false) {
    try {
    let dateToday = moment().startOf('day');
    let dateToCompare = moment(date, "YYYY-MM-DD").startOf('day');
    if(debug) {
      console.log("Selected date", date);
    }

    /**
     * Validate past dates
     */
    if(!dateToCompare.isSameOrAfter(dateToday)) {
      throw "Past date";
    }

    /**
     * Validate same day appointment booking according to scheduler setting
     */
    let sameDayBookingSetting = settings && settings["scheduler_same_day_appointment"];
    if(debug) {
      console.log("sameDayBookingSetting", sameDayBookingSetting);
    }
    switch(sameDayBookingSetting) {
      case "Don't let clients book same day appointments":
        if(dateToCompare.isSame(dateToday)) {
          throw "Don't let clients book same day appointments";
        }
        break;

      case "Don't let clients book same or following day appointments":
        if(dateToCompare.isSame(dateToday) || dateToCompare.isSame(moment().add(1,'days').startOf('day'))) {
          throw "Don't let clients book same or following day appointments";
        }
        break;
      
      case "Don't let clients book appointments within a week":
        if(dateToCompare.isBefore(moment().add(1,'weeks'))) {
          throw "Don't let clients book appointments within a week";
        }
        break;
    }

    /**
     * Validate max scheduling date setting
     */
    let maxSchedDateSetting = settings && settings["scheduler_future_date_booking_allowed"];
      if(maxSchedDateSetting) {
      const allowedMonths = (function() {
        const matches = maxSchedDateSetting.match(/\d+/);
        return matches ? parseInt(matches[0]) : 0;
      })();
      const monthDifference = dateToCompare.diff(dateToday, 'months');
      if (!(allowedMonths && monthDifference >= 0 && monthDifference < allowedMonths)) {
        throw "Date is too far in future";
      }
    }

    /**
     * Validate blocked date settings
     */
    let calendarBlocks = settings && settings["scheduler_blocked_dates"];
    calendarBlocks = calendarBlocks && JSON.parse(calendarBlocks);
    if(calendarBlocks && calendarBlocks.length > 0) {
      for(let i = 0; i < calendarBlocks.length; i++) {
        let block = calendarBlocks[i];
        let blockTimezone = block.timezone;
        if(!block.full_day_block) {
          continue;
        }

        let startDate   = moment(block.from_date, "YYYY-MM-DD");
        let endDate     = moment(block.to_date, "YYYY-MM-DD");
        let compareDate = moment(momentTZ(date).tz(blockTimezone, true).format("YYYY-MM-DD"));
        if(debug) {
          console.log("dates", startDate, endDate, compareDate);
        }
        if(compareDate.isBetween(startDate, endDate) || compareDate.isSame(startDate) || compareDate.isSame(endDate)) {
          throw "Date is blocked"
        }
      }
    }

    return true;
  } catch(err) {
    // console.error(err, date);
    return false;
  }
  }

  export function parseTimeString(time) {
    return moment(time, ['h:mm a', 'H:mm']);
  }

  export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  export const validateConditions = (allPages) => {
    let allQuestionAnswers = [];
    for(let i = 0; i < allPages.length; i++) {
      if(allPages[i] && allPages[i].questions && allPages[i].questions.length > 0) {
        allQuestionAnswers= [...allPages[i].questions, ...allQuestionAnswers];
      }
    }
    return allPages.map(page => {
      page.visible = validatePageConditions(page, allQuestionAnswers);
      page.questions = page.questions.map(q => {
        q.visible = validateQuestionConditions(q, allQuestionAnswers);
        return q;
      })
      return page;
    })
  }
  
  export const validatePageConditions = (page, questionAnswers) => {
    try {
      let showPage = false;
      if(!page.page_depend_q_id || !page.page_depend_q_ans || !page.page_depend_q_ans?.length) {
        showPage = true;
      } else {
        let dependQuestionAns = JSON.parse(page.page_depend_q_ans);
        let questionIndex = questionAnswers.findIndex(q => ((q.id == page.page_depend_q_id) && q.visible));
        let foundQuestion = questionAnswers[questionIndex];
        let dependSourceAnswer = null;
        if(questionIndex !== -1 && foundQuestion) {
          dependSourceAnswer = foundQuestion.answer;
          // if answer is singular and not an array
          if(typeof dependSourceAnswer == "string") {
            if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
              showPage = true;
            }
          // if answer is an array, check if any of the given answers match the condition
          } else if(Array.isArray(dependSourceAnswer)) {
            let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
            if(findMatch) {
              showPage = true;
            }
          }
        }
      }
      return showPage;
    } catch(err) {
      console.log("Error validating page conditions", err);
      return true;
    }
  }
  
  export const validateQuestionConditions = (question, questionAnswers) => {
    if(!question) {
      return false;
    }
    let showQuestion = false;
    if(!question.depend_q_id || !question.depend_q_ans || !question.depend_q_ans?.length) {
      showQuestion = true;
    } else {
      let dependQuestionAns = JSON.parse(question.depend_q_ans);
      let questionIndex = questionAnswers.findIndex(q => ((q.id == question.depend_q_id) && q.visible));
      let foundQuestion = questionAnswers[questionIndex];
      let dependSourceAnswer = null;
      if(questionIndex !== -1 && foundQuestion) {
        dependSourceAnswer = foundQuestion.answer;
        // if answer is singular and not an array
        if(typeof dependSourceAnswer == "string") {
          if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
            showQuestion = true;
          }
        // if answer is an array, check if any of the given answers match the condition
        } else if(Array.isArray(dependSourceAnswer)) {
          let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
          if(findMatch) {
            showQuestion = true;
          }
        }
      }
    }
    return showQuestion;
  }

  export const getCurrentUrl = (location) => {
    return `${location.pathname}${location.search}`;
  }

  export function parseTimeIn24Hour(timeString) {
    const timeComponents = timeString.split(' ');
    const time = timeComponents[0];
    const ampm = timeComponents[1];
  
    const [hours, minutes] = time.split(':').map(Number);
  
    let adjustedHours = hours;
    if (ampm.toLowerCase() === 'pm' && hours < 12) {
        adjustedHours += 12;
    } else if (ampm.toLowerCase() === 'am' && hours === 12) {
        adjustedHours = 0;
    }
  
    return {
        hours: adjustedHours,
        minutes
    };
  }
  
  export function getAppointmentDateTime(date, timeString, timezone, isEndTime = false, startTimeString = null) {
    let parsedTimeObj = parseTimeIn24Hour(timeString);
    let dateTime = momentTZ.tz(date, timezone)
      .set("hours", parsedTimeObj.hours)
      .set("minutes", parsedTimeObj.minutes);
  
    // Check if end time crosses into the next day
    if (isEndTime && startTimeString) {
      let parsedStartTime = parseTimeIn24Hour(startTimeString);
      if (
        parsedTimeObj.hours < parsedStartTime.hours || 
        (parsedTimeObj.hours === parsedStartTime.hours && parsedTimeObj.minutes < parsedStartTime.minutes)
      ) {
        // Add a day if the end time is before the start time
        dateTime.add(1, 'day');
      }
    }
  
    return dateTime.toDate();
  }
  
  

  // Function to convert local date to UTC
export function convertLocalDateToUTC(localDateString, outputFormat = "YYYY-MM-DDTHH:mm:ss[Z]", inputFormat = "YYYY-MM-DD") {
   // Get current local time
   const currentLocalTime = momentTZ();

   // Combine current local date with the provided date string
   const combinedDateTime = momentTZ(localDateString, inputFormat).set({
       hour: currentLocalTime.hour(),
       minute: currentLocalTime.minute(),
       second: currentLocalTime.second(),
       millisecond: currentLocalTime.millisecond()
   });

  const utcDate = combinedDateTime.utc();

  return utcDate.format(outputFormat);
}

export function showDateInUtc(date, format = "dddd, MMM DD YYYY") {
  return moment.utc(date).format(format);
}

export const useSleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function safelyParseJSON(json, defaultValue = null) {
  if(!json) {
    return json
  }
  let parsed = defaultValue;

  try {
    if(isObject(json)) {
      return json;
    }
    parsed = JSON.parse(json);
  } catch(err) {
    console.error("Unable to parse JSON", err);
  }

  return parsed;
}

// Function to get start and end dates of the month and filter out past dates, adding 8 days before and after
export function getMonthStartAndEndDates(date) {
  // Parse the given date
  const givenDate = momentTZ(date);
  
  // Get start and end of the month, adding/subtracting 8 days
  const adjustedStart = givenDate.clone().startOf('month').subtract(8, 'days');
  const adjustedEnd = givenDate.clone().endOf('month').add(8, 'days');

  // Get current date
  const now = momentTZ();

  // Check if the start and end dates are valid
  const isStartInThePast = adjustedStart.isBefore(now); // Adjusted start date is in the past
  const isEndInTheFuture = adjustedEnd.isSameOrAfter(now); // Adjusted end date is in the future or today

  // Filter out past dates
  return {
    start: isStartInThePast ? now : adjustedStart.format('YYYY-MM-DD'),
    end: isEndInTheFuture ? adjustedEnd.format('YYYY-MM-DD') : null
  };
}


/**
 * check if a date is between a date range
 */
export function isBetweenDateRange(date, rangeStart, rangeEnd) {
  return (
    moment(date).isSameOrAfter(moment(rangeStart)) &&
    moment(date).isBefore(moment(rangeEnd))
  )
}

export function isDateWithinRange(dateToCheck, range, unit) {
  const startDate = moment();
  const endDate = moment().add(range, unit);

  const checkDate = moment(dateToCheck);

  return checkDate.isBetween(startDate, endDate, unit, '[]');
}