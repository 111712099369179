import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from 'react-redux';
import moment from "moment/moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as actions from 'store/actions';
import { currenDay_, currentDayFromMonth, currentDayFromMonth_, currenDatee_, bookingdatetime, bookingdate, convertTimezone, isDateValid, getAppointmentDateTime, convertLocalDateToUTC, useSleep, getMonthStartAndEndDates } from 'helpers/common'
import * as Yup from 'yup';
import * as Path from "Routes/paths";
import { getPracitionerDetails, getPractitionerSettings } from "store/services/authService";
import { getAppointmentSlots, getAppointmentTypeList, appointmentBook_ } from "store/services/appointmentService";
import PaymentForm from "components/shared/PaymentForm/Index";
import { getClientPackage } from "store/services/packageService";
import { Link } from "react-router-dom";
import { APPT_BOOKING_REASON_MAX, CALENDAR_ERROR } from "common/constants";
import { dashboard } from "store/services/appService";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "common/constantMessage";
import MyCalendar from "components/shared/MyCalendar";
import { apptBookReasonValidation } from "helpers/validationHelper";
const momentTZ = require('moment-timezone');

const BookAppointmentPop = ({
  bookAppointment,
  setBookAppointment,
  appointmentTypeList,
  dispatch,
  timeZoneListing,
  setBookApp,
  practitioner,
  clientRes,
  currentBooking,
  activePackage,
  practSettingsList,
}) => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Required'),
    last_name: Yup.string()
      .required('Required'),
    email: Yup.string()
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
    reason: apptBookReasonValidation
  });

  const validateData_ = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [step, setStep] = useState("1");

  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [clientData_, setClientData_] = useState([]);
  const [appointTypeId, setAppointTypeId] = useState(null);
  const [appointTypeData, setAppointTypeData] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [sending, setSending] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [blockDateSettings, setBlockDateSettings] = useState(null);

  //select days
  const [selectedDay_, setSelectedDay_] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const [stateSlotsVal, setStateSlotsVal] = useState([])

  const [availableTimes, setAvailableTimes] = useState(null);
  
  const localizer = momentLocalizer(moment);
  
  const [selectedDate, setSelectedDate] = useState(new Date()); // State to track the selected date

  const [timeZoneValue, setTimeZoneValue] = useState(moment.tz.guess());
  const [timezonesList, setTimezonesList] = useState(moment.tz.names());
  const [timezoneSearchValue, setTimezoneSearchValue] = useState("");
  const [calendarError, setCalendarError] = useState(null);

  const handleSelectEvent = event => {
    // Handle event selection if needed
    // console.log('Event selected:', event);
  };

  const handleNavigate = (date) => {
    getPractitionerAvailability(appointTypeData, date);
    if (!isDateValid(date, practSettingsList)) {
      setCalendarError(CALENDAR_ERROR);
      return;
    }
    setSelectedDate(date);
    setCalendarError(null);
  };

  const handleSelectSlot = (slotInfo) => {
    const newSelectedDate = moment(slotInfo.start).toDate();
    if (!isDateValid(newSelectedDate, practSettingsList)) {
      setCalendarError(CALENDAR_ERROR);
      return;
    }
    setSelectedDate(newSelectedDate);
    setCalendarError(null);
  };

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        await Promise.all([
          practitionerDetails(),
          appointmentTypes(),
          getClientActivePackage(),
          getPractSetttings(),
        ])
        dispatch(actions.persist_store({ loader: false }));
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, [])

  useEffect(() => {
    if(!selectedDate || !availableTimes?.days?.length) {
      return;
    }
    const parsedDate = moment(selectedDate).format("YYYY-MM-DD");
    const findAvailability = availableTimes?.days.find(row => row.date === parsedDate);
    if(!findAvailability || !findAvailability?.available || !findAvailability?.spots?.length) {
      setStateSlotsVal([]);
      return;
    }
    setStateSlotsVal(findAvailability?.spots);
  }, [selectedDate, availableTimes])

  useEffect(() => {
    if (practSettingsList) {
      let calendarBlocks = practSettingsList["scheduler_blocked_dates"];
      if (!calendarBlocks) {
        return;
      }

      calendarBlocks = JSON.parse(calendarBlocks);
      setBlockDateSettings(calendarBlocks);
    }
  }, [practSettingsList])

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      width: "100%",
    };
    return {
      style,
    };
  };

  const [bookingValues, setBookingValues] = useState({
    // client_id:clientRes ? clientRes?.id : "",
    appointment_type: "",
    first_name: clientRes ? clientRes?.first_name : "",
    last_name: clientRes ? clientRes?.last_name : "",
    email: clientRes ? clientRes?.email : "",
    phone: clientRes ? clientRes?.phone : "",
    start_time: "",
    end_time: "",
    // appointment_timezone: "",
    appointment_timezone_client: 'no',
    duration: "",
    reason: "",
  })

  const handleBookAppointment = () => {
    setBookAppointment(!bookAppointment);
    setStep("1")
  };

  const getClientActivePackage = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientPackage());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      // setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const practitionerDetails = (client) => {
    try {
      dispatch(getPracitionerDetails());
    } catch (err) {
      console.log("Error fetching pracitioner details", err);
    }
  }

  const getPractSetttings = async () => {
    try {
      await dispatch(getPractitionerSettings());
    } catch (err) {
      console.log("Error fetching pracitioner settings", err);
    }
  }


  const appointmentTypes = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getAppointmentTypeList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error fetching pracitioner details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }
  const onClickAppointType = async (data) => {
    try {
      setAppointTypeId(data?.id);
      setAppointTypeData(data);
      getPractitionerAvailability(data, selectedDate);
      setStep("2")

    } catch (err) {
      console.log(err);
      // setButtonDisable(false)
      setStateSlotsVal([]);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const getPractitionerAvailability = async (apptType, selectedDate, timezone = timeZoneValue) => {
    try {
      const range = getMonthStartAndEndDates(selectedDate);
      if(!range) {
        throw "Month range not valid";
      }
      
      let finalObj = {
        appointmentTypeId: apptType?.id,
        values: {
          start_date: range?.start,
          end_date: range?.end,
          client_appt_tz: timezone,
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      // setButtonDisable(true)
      let res = await dispatch(getAppointmentSlots(finalObj));
      dispatch(actions.persist_store({ loader: false }));
      // setButtonDisable(false)
      setAvailableTimes(res?.body);
    } catch(err) {
      console.error("Error fetching availability", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeTimezone = (timezone) => {
    setTimeZoneValue(timezone);
    setStep("2");
    getPractitionerAvailability(appointTypeData, selectedDate, timezone);
  }

  const slotselctForBooking = (slotsVal) => {
    try {
      setCurrentSlot(slotsVal);
      setStep(3)
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeBooking_ = useCallback(
    name => evt => {
      const value = evt.target.value;
      setBookingValues(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    [bookingValues, clientRes]
  );
  // useEffect(() => {
  //   if(accountList && accountList.length > 0) {
  //   for(let timeZone of accountList){
  //     if(timeZone.data_key === 'scheduler_timezone'){
  //       setTimeZoneValue(timeZone.data_set)
  //     }
  //   }
  // }
  // }, [timeZoneValue])
  useEffect(() => {
    setBookingValues({
      // client_id : clientRes? clientRes?.id:"",
      first_name: clientRes ? clientRes?.first_name : "",
      last_name: clientRes ? clientRes?.last_name : "",
      email: clientRes ? clientRes?.email : "",
      phone: clientRes ? clientRes?.phone : "",
    })
  }, [clientRes])

  const bookAppointment_ = async () => {
    try {
      const validation = await validateData_(bookingValues);
      if (validation.isValid) {
        let obj = {
          appointment_type: appointTypeId,
          start_time: getAppointmentDateTime(currentSlot.date, currentSlot.start_time, timeZoneValue),
          end_time: getAppointmentDateTime(currentSlot.date, currentSlot.end_time, timeZoneValue, true, currentSlot.start_time),
          // appointment_timezone: timeZoneValue,
          client_appt_tz: timeZoneValue,
          duration: appointTypeData ? appointTypeData.duration : 0,
          first_name: bookingValues.first_name ? bookingValues.first_name : clientRes?.first_name,
          last_name: bookingValues.last_name ? bookingValues.last_name : clientRes?.last_name,
          phone: bookingValues.phone ? bookingValues.phone : clientRes?.phone,
          email: bookingValues.email ? bookingValues.email : clientRes?.email,
          // client_id : bookingValues.client_id,
          is_paid_appointment: appointTypeData?.is_paid_appointment,
          // booking_date: momentTZ.tz(moment(), timeZoneValue).toDate(),
          booking_date: moment().format("YYYY-MM-DD"),
          reason: bookingValues?.reason,
        }
        obj = JSON.stringify(obj)
        setSending(true)
        setButtonDisable(true)
        dispatch(actions.persist_store({ loader: true }));
        await dispatch(appointmentBook_(obj));
        await dispatch(dashboard());
        setSending(false)
        // setBookingValues({})
        setButtonDisable(false)
        if (!activePackage && appointTypeData.is_paid_appointment == "yes") {
          setStep("8")
        } else {
          setStep("4")
        }
        dispatch(actions.persist_store({ loader: false }));
        getClientActivePackage();
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setSending(false)
      setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  };

  const handleChangClose = () => {
    setStep('1')
    setAppointTypeData(null)
    setAppointTypeId(null)
    setCurrentSlot(null)
    // setBookingValues({})
    setButtonDisable(false)
  }
  //get client list
  async function getClientList() {
    try {
      let res = await dispatch(appointmentClientList_());
      setClientData_(res?.body)
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    // getClientList()
  }, [bookAppointment])

  const checkIfValidDay = (date) => {
    try {
      let isValid = isDateValid(date, practSettingsList);
      if (!isValid) {
        return {
          className: "blocked-day"
        }
      }

    } catch (err) {
      // console.log("Error validating date", err);
    }
  }

  const makePayment = () => {
    try {
      // setButtonDisable(true);
      setStep('8')
    } catch (err) {
      console.log("Error processing payment");
      setButtonDisable(false);
    }
  }

  const apptPaymentCallback = async (err, result) => {
    try {
      if(err) {
        console.error("Payment Failed", err);
        ERROR_MESSAGE(err?.message || "Payment failed");
        return;
      }
      dispatch(actions.persist_store({ loader: true }));
      await useSleep(2000);
      SUCCESS_MESSAGE("Payment succeeded");
      setStep("4");
      await dispatch(dashboard());
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("error in payment callback", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  if (!isLoaded) {
    return "";
  }

  return (
    <Modal
      show={bookAppointment}
      onHide={handleBookAppointment}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      scrollable="true"
    >
      <Modal.Body>
        <div
          className="px-3"
          style={{ background: "#f2f2f2", minHeight: "100vh" }}
        >
          <Row className="justify-content-center">
            <Col className="my-2" lg="10">
              {step == "1" ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Book an Appointment with {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2">
                        Please select the type of appointment that you would
                        like to make to view the available dates/times.
                      </p>
                    </div>
                    <div className="CardBody py-3">
                      {appointmentTypeList && appointmentTypeList.length > 0 && appointmentTypeList.map((list_, i) => {
                        return (
                          <div className="py-2" key={i}>
                            <div
                              className="commonCardBox p-3"
                              style={{ background: "#f6f6f6" }}
                            >
                              <div className="top py-2">
                                <h4 className="m-0 fw-sbold">{list_.title}</h4>
                                <span className="text-muted m-0">
                                  ({list_.duration} minutes)
                                </span>
                              </div>

                              <div className="content">
                                {list_.is_paid_appointment === 'yes' &&
                                  <div className="py-2">
                                    <h6 className="m-0">Price : {list_.currency_sign}{list_.price} ({list_.currency_code})</h6>
                                    {activePackage && <p>Package Active</p>}
                                  </div>
                                }
                                <div className="py-2">
                                  <h6 className="m-0">Description</h6>
                                  <p className="m-0">
                                    {list_.description}
                                  </p>
                                </div>
                                <div className="py-2">
                                  <div className="btnWrp mt-2 FormbtnWrpper">
                                    <Button
                                      // onClick={() => setStep("2")}
                                      onClick={() => onClickAppointType(list_)}
                                      // disabled={buttonDisable ? true :  false}
                                      className="d-flex align-items-center justify-content-center commonBtn "
                                    >
                                      Select
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              ) : step == "2" ? (
                <>
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0 mb-2">
                          Book an Appointment with {practSettingsList?.scheduler_display_name}
                        </h2>
                        <p className="m-0">
                          Select a highlighted date in the calendar to view
                          available times below.
                        </p>
                        <p className="m-0">
                          All dates/times are in <b> {timeZoneValue}</b>{" "}
                          time zone.
                        </p>
                        <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                          <li className="">
                            <Button
                              onClick={() => setStep("7")}
                              className="themeLink fw-sbold border-0 p-0"
                              variant="transparent"
                            >
                              Change Time Zone
                            </Button>
                          </li>
                          <li className="">
                            <Button
                              onClick={() => setStep("1")}
                              className="themeLink fw-sbold border-0 p-0"
                              variant="transparent"
                            >
                              Start Over
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="py-2">
                        <h6 className="m-0">{appointTypeData?.title}</h6>
                      </div>
                      <div className="py-2">
                        <h6 className="m-0">Duration : {appointTypeData?.duration} minutes</h6>
                      </div>
                      {appointTypeData?.is_paid_appointment === 'yes' &&
                        <div className="py-2">
                          <h6 className="m-0">Price : {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData?.currency_code})</h6>
                        </div>
                      }
                      <div className="CardBody py-3">
                        <Row>
                          <Col lg="12" className="my-2">
                            <MyCalendar
                              localizer={localizer}
                              events={[]}
                              eventPropGetter={eventStyleGetter}
                              startAccessor="start"
                              endAccessor="end"
                              onSelectEvent={handleSelectEvent}
                              // defaultDate={selectedDate}
                              views={['month']}
                              onNavigate={handleNavigate}
                              style={{ height: 500 }}
                              selectable
                              selected={selectedDate}
                              onSelectSlot={handleSelectSlot}
                              dayPropGetter={checkIfValidDay}
                              longPressThreshold={1}
                            />
                            {calendarError &&
                              <p className="invalid-feedback d-block">{calendarError}</p>
                            }
                          </Col>
                          {selectedDate && (
                            <Col lg="12" className="my-2">
                              <div className="commonContent">
                                <h4 className="m-0 py-2">
                                  Open Times on {currentDayFromMonth_(selectedDate)}
                                </h4>
                                {timeZoneValue && (
                                  // <p>All dates/times are in <strong>{timeZoneValue}</strong> time zone.</p>
                                  <p>
                                    All dates/times are in <strong>{timeZoneValue}</strong> time zone.
                                  </p>
                                )}
                                <ul className="list-unstyled ps-0 mb-0 py-2">
                                  {stateSlotsVal.length > 0 ? (
                                    <>
                                      {stateSlotsVal.map((slot, i) => {
                                        return (
                                          <li className="py-1" key={i}>
                                            <div
                                              className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap"
                                              style={{ background: "#f6f6f6" }}
                                            >
                                              <h6 className="m-0 py-2">
                                                {slot.start_time} to {slot.end_time} on {currentDayFromMonth_(slot.date)}
                                              </h6>
                                              <Button
                                                onClick={() => slotselctForBooking(slot)}
                                                className="d-flex align-items-center justify-content-center commonBtn btnSm px-4"
                                                disabled={slot?.is_booked}
                                              >
                                                Select
                                              </Button>
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <p className="m-0">No slots found, please choose another date/time.</p>
                                  )}
                                </ul>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </>
                </>
              ) : step == "3" ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Book an Appointment with {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2">Finalize your appointment.</p>
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                        <li className="">
                          <Button
                            onClick={() => setStep("1")}
                            className="themeLink fw-sbold border-0 p-0"
                            variant="transparent"
                          >
                            Start Over
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className="CardBody py-3">
                      <div className="py-2">
                        <div
                          className="commonCardBox p-3"
                          style={{ background: "#f6f6f6" }}
                        >
                          <div className="top py-2">
                            <h4 className="m-0 fw-sbold">{appointTypeData?.title}</h4>
                            <span className="text-muted m-0">
                              ({appointTypeData?.duration} minutes)
                            </span>
                            {appointTypeData?.is_paid_appointment === 'yes' &&
                              <div className="py-2">
                                <h6 className="m-0">Price : {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData?.currency_code})</h6>
                              </div>
                            }
                            <h6 className="m-0 fw-sbold">
                              {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(currentSlot?.date)}
                            </h6>
                          </div>
                          <div className="content">
                            <div className="py-2">
                              <p className="m-0 py-2">
                                Please provide the following information for your appointment.
                              </p>
                              <Row>
                                <Col lg="12" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label m-0 fw-sbold"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    name="first_name"
                                    class="form-control"
                                    onChange={handleChangeBooking_("first_name")}
                                    value={bookingValues.first_name || clientRes?.first_name}
                                  />
                                  {validationErrors.first_name && (
                                    <div className="text-danger">{validationErrors.first_name}</div>
                                  )}
                                </Col>
                                <Col lg="12" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label m-0 fw-sbold"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    name="last_name"
                                    class="form-control"
                                    onChange={handleChangeBooking_("last_name")}
                                    value={bookingValues.last_name || clientRes?.last_name}
                                  />
                                  {validationErrors.last_name && (
                                    <div className="text-danger">{validationErrors.last_name}</div>
                                  )}
                                </Col>
                                <Col lg="12" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label m-0 fw-sbold"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    class="form-control"
                                    onChange={handleChangeBooking_("email")}
                                    value={bookingValues.email || clientRes?.email}
                                  />
                                  {validationErrors.email && (
                                    <div className="text-danger">{validationErrors.email}</div>
                                  )}
                                </Col>
                                {appointTypeData?.location &&
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      Location
                                    </label>
                                    <input
                                      type="text"
                                      name="location"
                                      class="form-control"
                                      disabled
                                      value={appointTypeData?.location === 'by_phone' ? 'By Phone' : appointTypeData?.location === 'in_person' ? 'In Person' : appointTypeData?.location === 'video_zoom' ? 'Video/Zoom' : ''}
                                    />
                                  </Col>
                                }
                                <Col lg="12" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label m-0 fw-sbold"
                                  >
                                    Phone #
                                  </label>
                                  <input
                                    type="number"
                                    name="phone"
                                    class="form-control"
                                    placeholder="+11234567890"
                                    onChange={handleChangeBooking_("phone")}
                                    value={bookingValues.phone || clientRes?.phone}
                                    max={15}
                                  />
                                  <p className="text-muted">Example format: +11234567890</p>
                                  {validationErrors.phone && (
                                    <div className="text-danger">{validationErrors.phone}</div>
                                  )}
                                </Col>
                                <Col lg="12" className="my-2">
                                  <label
                                    htmlFor=""
                                    className="form-label m-0 fw-sbold"
                                  >
                                    Reason for appointment / topics to discuss
                                  </label>
                                  <textarea
                                    name="reason"
                                    class="form-control resizable"
                                    onChange={handleChangeBooking_("reason")}
                                    value={bookingValues.reason}
                                    max={15}
                                  />
                                  <div className="d-flex justify-content-end">
                                    <span className="me-1">Characters used: {bookingValues.reason?.length || 0} / {APPT_BOOKING_REASON_MAX}</span>
                                  </div>
                                  {validationErrors.reason && (
                                    <div className="text-danger">{validationErrors.reason}</div>
                                  )}
                                </Col>
                                {/* <Col lg="12" className="my-2">
                                      <label
                                        htmlFor=""
                                        className="form-label m-0 fw-sbold"
                                      >
                                        Client in a different time zone?
                                      </label>
                                      <br />
                                      <div className="row">
                                        <div className="col-md-6">
                                            <input
                                            type="radio"
                                            name="appointment_timezone_client"
                                            class="form-radio"
                                            onChange={handleChangeBooking_("appointment_timezone_client")}
                                            value="no"
                                            checked={bookingValues.appointment_timezone_client === 'no' ? true : false}
                                          />
                                          &nbsp;<label
                                            htmlFor=""
                                            className="form-label m-0 fw-sbold"
                                          >
                                            No
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                            type="radio"
                                            name="appointment_timezone_client"
                                            class="form-radio"
                                            onChange={handleChangeBooking_("appointment_timezone_client")}
                                            value="yes"
                                            checked={bookingValues.appointment_timezone_client === "yes" ? true : false}
                                          />
                                          &nbsp;<label
                                            htmlFor=""
                                            className="form-label m-0 fw-sbold"
                                          >
                                            Yes
                                          </label>
                                        </div>
                                      </div>
                                    </Col> */}
                                {/* {bookingValues.appointment_timezone_client === 'yes' &&
                                    <>
                                     <p>Your appointment will still be on {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(selectedDay_)} but we will notify your client of the appointment using the time zone you select below for them:</p><br />
                                      <label
                                        htmlFor=""
                                        className="form-label m-0 fw-sbold"
                                      >
                                        Client's time zone: &nbsp;{timeZoneValue} <a href="#">&nbsp;&nbsp;change</a>
                                      </label>
                                    {accountList.length > 0 &&
                                      accountList.map((settingList, i) => {
                                        if(settingList.data_key === "scheduler_timezone"){
                                        return (
                                          <Col lg="12" className="my-2">
                                            <label
                                              htmlFor=""
                                              className="form-label m-0 fw-sbold"
                                            >
                                            </label>
                                            <Form.Select
                                            aria-label="Default select example"
                                            className="form-control"
                                            name="appointment_timezone"
                                            onChange={handleChangeBooking_(
                                              "appointment_timezone"
                                            )}
                                          >
                                            <option value="" disabled>
                                              --Select Time Zone--
                                            </option>
                                            {timeZoneListing.length > 0 &&
                                              timeZoneListing.map(
                                                (zone, i) => {
                                                  return (
                                                    <option
                                                      value={zone.timezone}
                                                      selected={
                                                        settingList.data_set ===
                                                        zone.timezone
                                                          ? "selected"
                                                          : ""
                                                      }
                                                    >
                                                      {zone.timezone}
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </Form.Select>
                                          </Col>
                                          )
                                        }
                                    })}
                                    </>
                                    } */}
                                {appointTypeData?.is_paid_appointment === 'yes' && !activePackage ? (
                                  <Col lg="12" className="my-2">
                                    Payment of {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData?.currency_code}) is required to finalize this booking.
                                    <div className="FormbtnWrpper mt-2">
                                      <Button
                                        type="submit"
                                        disabled={buttonDisable ? true : false}
                                        // onClick={() => setStep("6")}
                                        onClick={() => bookAppointment_()}
                                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                      >
                                        {buttonDisable ? 'Wait...' : 'Payment'}
                                      </Button>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col lg="12" className="my-2">

                                    <div className="FormbtnWrpper mt-2">
                                      <Button
                                        type="submit"
                                        disabled={buttonDisable ? true : false}
                                        onClick={() => bookAppointment_()}
                                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                      >
                                        {buttonDisable ? 'Wait...' : 'Finalize Booking'}
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : step == "4" ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Your Appointment with {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2 themeLink fw-sbold">
                        Appointment confirmed!
                      </p>
                    </div>
                    <div className="CardBody py-3">
                      <div className="py-2">
                        <div
                          className="commonCardBox p-3"
                          style={{ background: "#f6f6f6" }}
                        >
                          <div className="top py-2">
                            {/* {currentBooking?.payment_status === "success" ? (
                              <>
                                <p className="m-0 fw-sbold">
                                  Your payment was successful
                                </p>
                                <p className="m-0 fw-sbold">
                                  Your appointment has been confirmed. We have
                                  also sent you an email confirmation as well.
                                </p>
                              </>
                            ):(
                              <p className="m-0 fw-sbold">
                                We are confirming the status of your payment, it may
                                take a few minutes. Please try refreshing the page after some time.
                              </p>
                            )} */}
                            <p className="m-0 fw-sbold">
                              Your appointment has been confirmed. We have
                              also sent you an email confirmation as well.
                            </p>
                            <div className="commonCardBox my-2 p-3 bg-white">
                              <div className="top m-0 py-2">
                                <h4 className="m-0 fw-sbold">{appointTypeData?.title}</h4>
                                <p className="m-0">{appointTypeData?.duration} minutes</p>
                                {(appointTypeData?.location === "video_zoom" && appointTypeData?.video_url) && (
                                  <p>
                                    Video Link:{" "}
                                    <a
                                      href={appointTypeData?.video_url}
                                      target="_blank"
                                    >
                                      {appointTypeData?.video_url}
                                    </a>
                                  </p>
                                )}
                                <p></p>
                                <h6 className="m-0 py-2">
                                  {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth_(currentSlot?.date)}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <div className="py-2">
                              <p className="m-0 text-success">
                                Everything is complete. Your confirmation code
                                is {currentBooking && currentBooking.uuid}. <br /> Please click the
                                'Close' button to go back to the main page.
                              </p>
                            </div>
                            <div className="py-2">
                              <div className="btnWrp mt-2 FormbtnWrpper">
                                <Button
                                  onClick={() => handleChangClose()}
                                  className="d-flex align-items-center justify-content-center commonBtn "
                                >
                                  Close
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : step == '6' ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Book an Appointment for {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2">Finalize your appointment.</p>
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                        <li className="">
                          <Button
                            onClick={() => setStep("1")}
                            className="themeLink fw-sbold border-0 p-0"
                            variant="transparent"
                          >
                            Start Over
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className="CardBody py-3">
                      <div className="py-2">
                        <div
                          className="commonCardBox p-3"
                          style={{ background: "#f6f6f6" }}
                        >
                          <div className="top py-2">
                            <h4 className="m-0 fw-sbold">{appointTypeData?.title} <span className="text-muted m-0">
                              ({appointTypeData?.duration} minutes)
                            </span></h4>
                            <h6 className="m-0 fw-sbold">
                              {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(currentSlot?.date, timeZoneValue)}
                            </h6>
                            {appointTypeData?.is_paid_appointment === 'yes' &&
                              <div className="py-2">
                                <h6 className="m-0">Pay {appointTypeData?.currency_code}{appointTypeData?.price} to finalize your appointment</h6>
                              </div>
                            }
                            Your appointment slot will be held for 15 minutes.

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : step == "7" ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Book an Appointment for {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2">Select a new time zone below.</p>
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                        <li className="">
                          <Button
                            onClick={() => setStep("1")}
                            className="themeLink fw-sbold border-0 p-0"
                            variant="transparent"
                          >
                            Start Over
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className="CardBody py-3">
                      <div className="py-2">
                        <div
                          className="commonCardBox p-3"
                          style={{ background: "#f6f6f6" }}
                        >
                          <input
                            className="form-control"
                            onChange={(e) => {
                              setTimezoneSearchValue(e.target.value.toLowerCase());
                            }}
                            value={timezoneSearchValue}
                          />
                          <div className="py-2">
                            {timezonesList && timezonesList.length > 0 &&
                              timezonesList.map(timezone => {
                                if (timezoneSearchValue && !timezone.toLowerCase().includes(timezoneSearchValue)) {
                                  return null;
                                }
                                return (
                                  <div className="py-1">
                                    <Link
                                      onClick={() => {
                                        handleChangeTimezone(timezone);
                                      }}
                                    >
                                      {timezone}
                                    </Link>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : step == "8" ? (
                <>
                  <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                    <div className="top py-2 border-bottom bg-white ">
                      <h2 className="m-0">
                        Book an Appointment for {practSettingsList?.scheduler_display_name}
                      </h2>
                      <p className="m-0 py-2">Select a new time zone below.</p>
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                        <li className="">
                          <Button
                            onClick={() => setStep("1")}
                            className="themeLink fw-sbold border-0 p-0"
                            variant="transparent"
                          >
                            Start Over
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className="CardBody py-3">
                      <div className="py-2">
                        <div
                          className="commonCardBox p-3"
                          style={{ background: "#f6f6f6" }}
                        >
                          {currentBooking &&
                            <PaymentForm
                              appointment={currentBooking}
                              userId={clientRes.id}
                              returnUrl={`${process.env.REACT_APP_URL}${Path.dashboard}`}
                              callback={apptPaymentCallback}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            {/* <Col lg="12">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  onClick={handleBookAppointment}
                >
                  Close
                </Button>
              </Col> */}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="gap-10 FormbtnWrpper">
        <Button
          className="d-flex align-items-center justify-content-center commonBtn btnSm"
          onClick={handleBookAppointment}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToPros = (state) => {
  return {
    practitioner: state.Auth.practitionerDetails,
    practSettingsList: state.Auth.practitionerSettings,
    appointmentTypeList: state.Appointment?.appointmentTypes || [],
    currentBooking: state.Appointment?.currentBooking,
    clientRes: state.Profile.user,
    activePackage: state.Package.activePackage,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(BookAppointmentPop);
