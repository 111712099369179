import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ColorCards from "./Component/ColorCards/ColorCards";
import ToDoItem from "./Component/ToDoItem";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
import parse from "html-react-parser";

// icns
import { ReactComponent as RecentIcn2 } from "../../../Assets/Images/icn/Recent2.svg";

// images
import { DATE_FORMAT, FULL_NAME, isDateWithinRange } from "helpers/common";
import { getPracitionerDetails } from "store/services/authService";
import moment from "moment";
import AppointmentList from "./Component/AppointmentList/Index";
import { profile } from "store/services/profileService";
import { dashboard } from "store/services/appService";

const Dashboard = ({
  dispatch,
  todoItems,
  completedToDoItems,
  completedToDoCount,
  pendingToDoCount,
  recommendations,
  appointmentCount,
  practitioner,
  profileDetails,
  announcements
}) => {
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        dispatch(actions.persist_store({ loader: true }));
        await dispatch(profile());
        await dispatch(dashboard());
        // dispatch(getAppointmentList());
        // dispatch(getRecommendationList());
        await dispatch(getPracitionerDetails());
        await dispatch(actions.persist_store({ loader: false }));
        setLoading(false);
      } catch (error) {
        console.log("error getting dashboard data", error);
        dispatch(actions.persist_store({ loader: false }));
        setLoading(false);
      }
    }

    init();
  }, [])

  useEffect(() => {
    if(profileDetails?.terms_accepted === 'no'){
      navigate(Path.privacyPolicyAgreement)
    }
  }, [profileDetails]);

  return (
    <>
      <section className="dashboard d-flex align-items-start ">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="commonContent py-2">
                  {profileDetails &&
                    <>
                      <h3 className="m-0 fw-bold">Hi {profileDetails?.first_name}, Welcome Back</h3>
                      {/* <p className="m-0">
                        When a client logs a meal or feeling we'll show it here.
                      </p> */}
                    </>
                  }
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <ColorCards
                completedToDoCount={completedToDoCount}
                pendingToDoCount={pendingToDoCount}
                appointmentCount={appointmentCount}
              />
            </Col>
            <Col lg="12" className="my-2">
              <Row>
                <Col lg="8" className="my-2">
                  <div className="py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom">
                        <h3 className="m-0 py-2 fw-bold">
                          To-Do Items
                        </h3>
                      </div>
                      <div className="CardBody px-lg-4 px-3 py-3">
                        {isLoading ?
                          <p className="text-muted">Loading...</p>
                          :
                          <Row className="listView">
                            {todoItems && todoItems.length > 0 ? (
                              todoItems.map((todo) => <ToDoItem key={todo.todo_id} todo={todo} />)
                            ) : (
                              <div className="text-center py-2">
                                <div className="icn">
                                  <RecentIcn2 />
                                </div>
                                <div className="content py-2">
                                  <h6 className="fw-bold m-0">No To-Do Items</h6>
                                </div>
                              </div>
                            )}
                          </Row>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom">
                        <h3 className="m-0 py-2 fw-bold">
                          Completed To-Do Items
                        </h3>
                      </div>
                      <div className="CardBody px-lg-4 px-3 py-3">
                        {isLoading ?
                          <p className="text-muted">Loading...</p>
                          :
                          <Row className="listView">
                            {(completedToDoItems && completedToDoItems.length > 0) ? (
                              completedToDoItems.map((todo) => <ToDoItem key={todo.todo_id} todo={todo} />)
                            ):(
                              <div className="text-center py-2">
                                <div className="icn">
                                  <RecentIcn2 />
                                </div>
                                <div className="content py-2">
                                  <h6 className="fw-bold m-0">No To-Do Items</h6>
                                </div>
                              </div>
                            )}
                          </Row>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                      <div className="commonCardBox border h-100">
                        <div className="top px-lg-4 px-3 py-2 border-bottom">
                          <h3 className="m-0 py-2 fw-bold">
                            Announcements
                          </h3>
                        </div>
                        <div
                        className="CardBody px-lg-4 px-3 py-3 announcements"
                        style={{ overflowY: "auto", maxHeight: "400px" }}
                      >
                        { isLoading ? (
                          <p className="text-muted">Loading...</p>
                        ):announcements?.length > 0 ? (
                          <ul className="list-unstyled">
                            {announcements.map((announcement, idx) => {
                              let createdAt = DATE_FORMAT(announcement.created_at);
                              return (
                                <li className="my-2 position-relative" key={idx}>
                                  {isDateWithinRange(announcement.created_at, 7, "days") && (
                                    <span className="new-badge">•</span>
                                  )}
                                  {announcement?.description ? (
                                    <Accordion className="ColoredAcorrdion">
                                      <Accordion.Header
                                        className=""
                                        style={{
                                          background: "#F9FAFF",
                                          // border: "1px solid #2B3674",
                                          color: "#2B3674",
                                        }}
                                      >
                                        <span className="toggleBtn position-absolute"></span>
      
                                        <div className="d-flex align-items-center gap-10">
                                          {announcement.title}
                                        </div>
                                        <p className="m-0 text-dark pt-2">
                                          {createdAt}
                                        </p>
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          borderTop: "1px solid #2B3674",
                                        }}
                                      >
                                        <div className="inner">
                                          {parse(announcement.description)}
                                        </div>
                                      </Accordion.Body>
                                    </Accordion>
                                  ):(
                                    <div className="announcement-item">
                                      <div className="d-flex align-items-center gap-10 justify-content-between">
                                        <div>
                                          {announcement.title}
                                        </div>
                                      </div>
                                      <p className="m-0 text-dark pt-2">
                                        {createdAt}
                                      </p>
                                    </div>
                                  )}
                                </li>
                              )
                            })}

                          </ul>
                        ):(
                          <div className="text-center py-2">
                            <div className="icn">
                              <RecentIcn2 />
                            </div>
                            <div className="content py-2">
                              <h6 className="fw-bold m-0">
                                No annoucements to show
                              </h6>
                              <p className="m-0">
                                When a announcement is added we'll show it here.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      </div>
                    </div>
                </Col>
                <Col lg="4" className="my-2">
                  <div className="py-2">
                    <div className="commonCardBox border h-100">
                      <div className="top px-lg-4 px-3 py-2 border-bottom">
                        <h3 className="m-0 py-2 fw-bold">
                          Recommendation
                        </h3>
                      </div>
                      <div className="CardBody px-lg-4 px-3 py-3">
                      {recommendations && recommendations.length > 0 ?
                          recommendations.map(recommendation => {
                            return (
                              <Link to={Path.recommendation}>
                                <div className="d-flex align-items-center py-2">
                                  <div className="imgWrp me-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="27"
                                      viewBox="0 0 25 27"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_8148)">
                                        <path
                                          d="M22.0702 2.5918H20.8983V0.560547H18.9452V2.5918H6.05457V0.560547H4.10144V2.5918H2.92957C1.31414 2.5918 -0.00012207 3.95863 -0.00012207 5.63867V23.5137C-0.00012207 25.1937 1.31414 26.5605 2.92957 26.5605H22.0702C23.6856 26.5605 24.9999 25.1937 24.9999 23.5137V5.63867C24.9999 3.95863 23.6856 2.5918 22.0702 2.5918ZM23.0468 23.5137C23.0468 24.0737 22.6087 24.5293 22.0702 24.5293H2.92957C2.39109 24.5293 1.953 24.0737 1.953 23.5137V10.1074H23.0468V23.5137ZM23.0468 8.07617H1.953V5.63867C1.953 5.07866 2.39109 4.62305 2.92957 4.62305H4.10144V6.6543H6.05457V4.62305H18.9452V6.6543H20.8983V4.62305H22.0702C22.6087 4.62305 23.0468 5.07866 23.0468 5.63867V8.07617Z"
                                          fill="#f4694b"
                                        />
                                        <path
                                          d="M3.71082 12.2402H5.66394V14.2715H3.71082V12.2402ZM7.61707 12.2402H9.57019V14.2715H7.61707V12.2402ZM11.5233 12.2402H13.4764V14.2715H11.5233V12.2402ZM15.4296 12.2402H17.3827V14.2715H15.4296V12.2402ZM19.3358 12.2402H21.2889V14.2715H19.3358V12.2402ZM3.71082 16.3027H5.66394V18.334H3.71082V16.3027ZM7.61707 16.3027H9.57019V18.334H7.61707V16.3027ZM11.5233 16.3027H13.4764V18.334H11.5233V16.3027ZM15.4296 16.3027H17.3827V18.334H15.4296V16.3027ZM3.71082 20.3652H5.66394V22.3965H3.71082V20.3652ZM7.61707 20.3652H9.57019V22.3965H7.61707V20.3652ZM11.5233 20.3652H13.4764V22.3965H11.5233V20.3652ZM15.4296 20.3652H17.3827V22.3965H15.4296V20.3652ZM19.3358 16.3027H21.2889V18.334H19.3358V16.3027Z"
                                          fill="#f4694b"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_8148">
                                          <rect
                                            width="25"
                                            height="26"
                                            fill="white"
                                            transform="translate(-0.00012207 0.560547)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <div className="content">
                                    <p className="m-0">Recommendation</p>
                                    <p className="m-0 text-dark fw-sbold">
                                      {moment(recommendation.update_at).format("MMM DD, YYYY h:mm A")}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            )
                          })
                          :
                          <div className="text-center py-2">
                            <div className="icn">
                              <RecentIcn2 />
                            </div>
                            <div className="content py-2">
                              <h6 className="fw-bold m-0">No Recommendations</h6>
                              {practitioner &&
                                <p className="m-0">
                                  Recommendations from {FULL_NAME(practitioner.first_name, practitioner.last_name)} will
                                  appear here.
                                </p>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <AppointmentList />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  let allTodoItems = state.ToDo.items;
  return {
    todoItems: state.App.dashboard?.pending_todo_forms?.data,
    completedToDoItems: state.App.dashboard?.completed_forms?.data,
    completedToDoCount: state.App.dashboard?.completed_forms?.total_count,
    pendingToDoCount: state.App.dashboard?.pending_todo_forms?.total_count,
    recommendations: state.App.dashboard?.recom_list,
    appointmentCount: state.App.dashboard?.upcoming_appointments?.total_count,
    announcements: state.App.dashboard?.announcements,
    practitioner: state.Auth.practitionerDetails,
    profileDetails: state.Profile?.user,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Dashboard);