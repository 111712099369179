import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as actions from 'store/actions';
import { getPackageDetailsById, getUserByUUID } from "store/services/packageService";
import { formatPrice, FULL_NAME, getCurrentUrl } from "helpers/common";
import PaymentForm from "./Components/PaymentForm/Index";
import { login } from "Routes/paths";

const PurchasePackage = ({ dispatch, packageDetails, isAuthenticated }) => {
  const [userDetails, setUserDetails] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const packageId = urlParams.get('package');
  const uuid = urlParams.get('client');

  if(!isAuthenticated) {
    localStorage.setItem("redirectTo", encodeURI(getCurrentUrl(location)));
    navigate(login);
  }

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        dispatch(actions.persist_store({ loader: true }));
        await Promise.all([
          getUserDetails(),
          getPackageDetails()
        ])
        setLoading(false);
        dispatch(actions.persist_store({ loader: false }));
      } catch(err) {
        console.log("Error occurred:", err);
        setLoading(false);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, [])

  const getUserDetails = async () => {
    try {
      if(!uuid) {
        return;
      }
      let data = {
        uuid: uuid
      }
      let response = await getUserByUUID(data);
      if(response.success === true) {
        setUserDetails(response.body);
      }
    } catch(err) {
      throw err
    }
  }

  const getPackageDetails = async () => {
    try {
      if(!packageId) {
        return;
      }
      let data = {
        packageId: packageId
      }
      await dispatch(getPackageDetailsById(data));
    } catch(err) {
      throw err
    }
  }

  return (
    <>
      <section
        className="w-100 position-relative d-flex align-items-center justify-content-center"
        style={{ background: "#f2f2f2", minHeight: "100vh" }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ):(!userDetails || !packageDetails) ? (
          <p>Package details not found</p>
        ):(
        <Container>
          <Row className="justify-content-center">
            <Col className="my-2" lg="10">
              <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                <div className="top py-2 border-bottom bg-white ">
                  <h2 className="m-0">
                    {FULL_NAME(userDetails.first_name, userDetails.last_name)}
                  </h2>
                  <p className="m-0 py-2">
                    Hi {userDetails.first_name},<br/>
                    {FULL_NAME(userDetails.practitionerUser.first_name, userDetails.practitionerUser.last_name)} has invited you to purchase the following package:
                  </p>
                </div>
                <div className="CardBody py-3">
                  <div className="py-2">
                    <div
                      className="commonCardBox p-3"
                      style={{ background: "#f6f6f6" }}
                    >
                      <div className="top py-2">
                        <h4 className="m-0 fw-sbold">{packageDetails.package_name}</h4>
                        <span className="text-muted m-0 is-multiline">{packageDetails.package_desc}</span>
                        <h6 className="m-0">Price: US${formatPrice(packageDetails.package_price)}</h6>
                      </div>
                      <div className="content">
                        <div className="py-2">
                          {showPaymentForm && packageDetails && userDetails ? 
                            <PaymentForm
                              packageId={packageDetails?.package_invite_id}
                              userId={userDetails.id}
                            />
                            :
                            <div className="btnWrp mt-2 FormbtnWrpper">
                              <Button
                                onClick={() => setShowPaymentForm(true)}
                                className="d-flex align-items-center justify-content-center commonBtn "
                              >
                                Purchase Package
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="py-2" lg="10">
              
            </Col>
          </Row>
        </Container>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    packageDetails: state.Package.details,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePackage);