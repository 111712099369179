import { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { createPaymentIntent } from 'store/services/packageService';
import useStripe from "hooks/useStripe";
import CheckoutForm from "components/shared/PaymentForm/CheckoutForm";
import { purchasePackageSuccess } from "Routes/paths";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ packageId, userId }) => {
  const [isLoading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [options, setOptions] = useState({
    clientSecret: "",
    stripeAccount: null
  })

  const stripe = useStripe(options.stripeAccount);

  useEffect(() => {
    createPayment();
  }, [])

  const handlePaymentFormLoad = () => {
    setLoading(false);
  }

  const createPayment = async () => {
    try {
      let data = {
        packageId: packageId,
        values: {
          currency: "USD",
          userId: userId,
        }
      }
      let response = await createPaymentIntent(data);
      if(response.success === true && response.body.clientSecret) {
        setOptions((options) => {
          return {
            ...options,
            clientSecret: response.body.clientSecret,
            stripeAccount: response.body.stripeAccount
          }
        })
        setIsReady(true);
      }
    } catch(err) {
      console.log("Error creating payment intent", err);
    }
  }


  return (
    <>
      { !isReady || !stripe ?
        <p>Loading...</p>
        :
        <Elements stripe={stripe} options={options}>
          <CheckoutForm
            onLoad={handlePaymentFormLoad}
            returnUrl={`${process.env.REACT_APP_URL}${purchasePackageSuccess}`}
          />
        </Elements>
      }
    </>
  );
};

export default PaymentForm