import React from "react";
import { Button, Container, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// icn
import { ReactComponent as MenuIcn } from "../../Assets/Images/icn/menu.svg";

// css
import "./Header.scss";

// images
import logo from "../../Assets/Images/logo.png";
import { UC_FIRST } from "helpers/common";
import { useSelector } from "react-redux";
import BannerMessage from "components/shared/BannerMessage";

const Header = ({ showSidebar, setSidebar }) => {
  const location = useLocation();
  const todoAutoSaveInProgress = useSelector(state => state.ToDo.autoSaveInProgress);
  const bannerSettings = useSelector((state) => state.App.bannerSettings);

  const handleSidebar = () => {
    setSidebar(!showSidebar);
  };

  return (
    <>
      <header
        className="siteHeader pb-2 sticky-top border-bottom"
        style={{ background: "#fff", zIndex: "99" }}
      >
        {bannerSettings?.message && (
          <BannerMessage
            containerClass="nta-banner"
            data={bannerSettings}
          />
        )}
        <Container fluid>
          <Nav className="d-flex align-items-center justify-content-between w-100 gap-10 py-2">
            <div className="left d-flex align-items-center gap-10">
              {/* <Link to="/dashboard">
              <img src={logo} alt="" className="img-fluid" />
            </Link> */}
              <Button
                onClick={handleSidebar}
                className="border-0 p-0 d-lg-none"
                variant="transparent"
              >
                <MenuIcn />
              </Button>
              <div className="d-flex align-items-center">
                <h1 className="pagetitle m-0 py-2 fw-bold ">
                  {UC_FIRST(location.pathname.split("/").pop().replace(/-/g, " "))}
                </h1>
                {todoAutoSaveInProgress && (
                  <p className="m-0 mx-2">(Saving...)</p>
                )}
              </div>
            </div>
            {/* <Link
              to="add-client"
              className="d-flex align-items-center btn text-white btnSm justify-content-center commonBtn px-3"
            >
              <span className="icn me-2">
                <WPlus />
              </span>{" "}
              Add New client{" "}
            </Link> */}
          </Nav>
        </Container>
      </header>
    </>
  );
};

export default Header;
